

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 
*{
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
} */

.label-bold .MuiTypography-root {
  font-weight: 550;
}
/* .MuiFormLabel-root{
  font-weight: bolder !important;
  color:rgb(87, 87, 87) !important

} */

.product_scroll::-webkit-scrollbar {
  width: 8px;
  height: 4px; /* width of the scrollbar */
}

.product_scroll::-webkit-scrollbar-track {
  background: #f1f1f1; /* background of the scrollbar track */
}

.product_scroll::-webkit-scrollbar-thumb {
  background: lightgray; /* color of the scrollbar thumb */
  border-radius: 10px; /* rounded corners for the thumb */
}

.product_scroll::-webkit-scrollbar-thumb:hover {
  background: lightgray; /* color when hovering over the scrollbar thumb */
}

